import styled from 'styled-components'

interface IDashedLine {
    marginTop?: string;
    marginBottom?: string;
    hiddenMobile?:boolean;
    width?:string;
}

const DashedLine = styled.div<IDashedLine>`
    background-image: linear-gradient(90deg, currentColor, currentColor 20%, transparent 21%, transparent 100%);
    background-size: 5px 1px;
    height: 1px;
    width: ${props=> props.width ? props.width : 100}%;
    display:inline-block;
    margin-top: ${({ marginTop })=> marginTop + "px" };
    margin-bottom: ${({ marginBottom })=> marginBottom + "px"};

    @media (max-width: 768px) {
        display: ${({ hiddenMobile })=> hiddenMobile ? 'none': 'inline-block' };
     }  
`;

export { IDashedLine, DashedLine}

