import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import addressAutocomplete from '../../services/dawaservice'
import './Address-Typeahead.scss';

export interface IAddressProps {
    change(text: IValidationResult): React.ChangeEvent<HTMLInputElement>;
    validAddress?(addressValid: boolean): boolean;
    focus?: ()=> void;
    touchStart?:()=> void;
    blur?:()=> void;
    placeholder: string;
    width?: number;
    fuzzy: boolean;
}

export interface IDawaSuggestion {
    tekst: string;
    adresse: {
        adgangsadresseid: String;
        adresseringsvejnavn: String;
        darstatus: 3;
        dør: String;
        etage: String;
        href: String;
        husnr: String;
        id: String;
        kommunekode: String;
        postnr: String;
        postnrnavn: String;
        status: 1;
        stormodtagerpostnr: String;
        stormodtagerpostnrnavn: String;
        supplerendebynavn: String;
        vejkode: String;
        vejnavn: String;
        x: Number;
        y: Number;
    }
}

export interface IValidationResult {
    value: IDawaSuggestion;
    errors: string[];
    valid: boolean;
}

export enum ScrollDirection{
    UP, DOWN
}

export const AddressTypeahead = ({ change, placeholder = 'Indtast adresse', width, validAddress, fuzzy, focus, touchStart, blur  }: IAddressProps) => {

    let [suggestions, setSuggestions] = useState([]);
    let [selectedIndex, setSelectedIndex] = useState(0);
    let [suggestionsClosed, setSuggestionsClosed] = useState<Boolean>(true);

    let isWaiting: boolean = false;
    let latestRequest: string = "";

    const addressRef = useRef<any>();
    const addressParentRef = useRef<any>();
    const suggestionsRef = useRef<any>();

    useEffect(() => {
        if (width) {
            addressParentRef.current.style.width = `${width}px`;
        }
    }, [width]);

    useEffect(() => {
        setSuggestionsClosed(suggestions.length === 0);
    }, [suggestions]);

    const throttle = (e: ChangeEvent<HTMLInputElement>, waitTime = 200) => {

        latestRequest = e.target.value;

        if (!isWaiting) {

            isWaiting = true;

            setTimeout(() => {

                addressAutocomplete(latestRequest, fuzzy).then(res => {

                    if (res.status === 429) {
                        throttle(e, waitTime + 500);
                        return;
                    }

                    setSuggestions(res.data);

                    isWaiting = false;
                });

            }, waitTime);
        }
    }

    const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {

        if (e.target.value.length > 1) {
            throttle(e);
        }
        else {
          
            resetScroll();
            setSuggestions([]);
            setSelectedIndex(0);
            setSuggestionsClosed(true);

        }
    };

    const handleKeyChange = (e: any) => {

        if (addressRef.current.value.length === 0) {

            
           
            resetScroll();
            setSuggestions([]);
            setSuggestionsClosed(true);

            return;
        }

        if (validAddress) {
            validAddress(false);
        }

        if (e.code === "ArrowUp") {

            if (selectedIndex === 0) {
                return;
            }

           setSelectedIndex(selectedIndex - 1);

           scrollSuggestions(ScrollDirection.UP);
        }

        if (e.code === "ArrowDown") {

            if (selectedIndex === suggestions.length - 1) {
                return;
            }

            setSelectedIndex(selectedIndex + 1);

            scrollSuggestions(ScrollDirection.DOWN);
        }

        if (e.code === "Enter") {

            let target = (suggestions[selectedIndex] as IDawaSuggestion);
            if (target) {
                selectSuggestion(target);
            }
        }
    };

    const resetScroll = ()=> {
        suggestionsRef.current.scrollTop = 0;
    }

    const scrollSuggestions = (direction:ScrollDirection)=> {

        if (!suggestionsRef.current) return;

        let height = document.querySelectorAll(".suggestions > div")[1].getBoundingClientRect().height;
     
        if (direction === ScrollDirection.UP){                  
            suggestionsRef.current.scrollTop = selectedIndex === 0 ? suggestions.length * height :  suggestionsRef.current.scrollTop -= height;        
        }
        else{
            suggestionsRef.current.scrollTop = selectedIndex === suggestions.length ? 0 :  suggestionsRef.current.scrollTop += height;
        }

    };

    const handleClick = () => {

        if (suggestions.length === 0) {
            setSuggestionsClosed(true);
            return;
        }

        setSuggestionsClosed(!suggestionsClosed);
    };

    const createResult = (address: IDawaSuggestion) => {

        change({
            value: address,
            valid: address !== null && address !== undefined,
            errors: address !== null ? [] : ["Ingen adresse valgt"]
        });
    }

    const selectSuggestion = (suggestion: IDawaSuggestion): void => {
        createResult(suggestion);
        addressRef.current.value = suggestion.tekst;
        setSuggestionsClosed(true);

        if (validAddress) {
            validAddress(true);
        }
    };

    return (
        <div>
            <div className="input-element-parent" ref={addressParentRef}>
                <input onTouchStart={touchStart} onFocus={focus} onBlurCapture={blur} className="input-element" onChange={handleTextChange} onKeyUp={handleKeyChange} onClick={handleClick} ref={addressRef} required></input>
                <div ref={suggestionsRef} className={`suggestions ${suggestionsClosed || suggestions.length === 0 ? 'suggestions-hidden' : ''}`} tabIndex={-1}>
                    {suggestions.map((item: IDawaSuggestion, index: number) =>
                        <div key={item.tekst} data-suggestion={JSON.stringify(item)} id={item.adresse.adgangsadresseid.toString()} className={index == selectedIndex ? 'selected-suggestion suggestion-item' : 'suggestion-item'} onClick={(item) => {

                            let target = (suggestions[index] as IDawaSuggestion);
                            if (target) {
                                selectSuggestion(target);
                                setSelectedIndex(index);
                            }

                        }}>{item.tekst}</div>
                    )}
                </div>
                <div className="input-placeholder">{placeholder}</div>
            </div>
        </div>
    )
}
