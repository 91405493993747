import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { BoxbuttonColorTheme } from "../../models/boxbutton/BoxbuttonColorTheme";
import '../boxbutton/Boxbutton.scss'

export interface ICheckboxProps {
    label: string;
    click(): void;
    theme: BoxbuttonColorTheme;
    width?: number;
    height?:number;
    fullWidth?: boolean;
    shadow?: boolean;
    border?: boolean;
}

export const Boxbutton = ({ click, label, theme, fullWidth, width, height, shadow, border }: ICheckboxProps) => {

    const boxbuttonRef = useRef<HTMLDivElement>();

    useEffect(() => {

        if (width && !fullWidth) {
            boxbuttonRef.current.style.width = width + "px";
        }

        if (height) {
            boxbuttonRef.current.style.height = height + "px";
        }

    });

    return (
        <div onClick={click} className={`box-button theme-${theme.toString()} ${fullWidth ? 'full-width-boxbutton' : ''} ${shadow ? 'boxbutton-shadow' : ''} ${border ? 'boxbutton-border' : ''}`} ref={boxbuttonRef}>         
                {label}
        </div>
    );
}



