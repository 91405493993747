import * as React from 'react';
import './Button.scss';
import { ButtonColorEnum } from '../../models/button/ButtonColorEnum';
import { ButtonTypeEnum } from '../../models/button/ButtonTypeEnum';

export interface ButtonProps {
  withShadow?: boolean,
  outline?: boolean,
  color?: ButtonColorEnum,
  disabled?: boolean,
  grow?: boolean,
  label: string,
  url?: string,
  type?: ButtonTypeEnum,
  id?: string,
  onClick?: () => void
}

export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  let color = ButtonColorEnum.WHITE;
  let type = ButtonTypeEnum.BUTTON;
  if (props.color) {
    color = props.color;
  }
  if (props.type) {
    type = props.type;
  }

  let classVar = "Button";
  if (props.withShadow) {
    classVar += " Button--shadow";
  }
  if (props.outline) {
    classVar += " Button--outline"
  }
  if (props.grow) {
    classVar += " Button--grow"
  }
  classVar += ` Button--${color}`;

  if (!props.url) {
    return (
      <button
        className={classVar}
        disabled={props.disabled}
        type={type}
        onClick={props.onClick}
        id={props.id}
      >
        {props.label}
        
      </button>
    )
  }

  return (
    <a href={props.url} className={classVar}
    >{props.label}</a>
  )
}

