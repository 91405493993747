import * as React from 'react';
import { Icon } from '../icon/Icon';
import { Button } from '../button/Button'
import { Loader } from '../loader/Loader'
import { useEffect, useState } from 'react';
import { AccordionMinimumHeight } from '../../models/accordion/AccordionMinimumHeight';
import { ButtonColorEnum } from '../../models/button/ButtonColorEnum';
import { LoaderSizeEnum } from '../../models/loader/LoaderSizeEnum';
import './Accordion.scss';
import { AccordionBackgroundColor } from '../../models/accordion/AccordionBackgroundColor';

export interface IAccordionProps {
    open: boolean;
    disabled?: boolean;
    label?: string;
    children?: React.ReactNode;
    loading?: boolean;
    loadingText?: string;
    loadingTitle?: string;
    minimumHeight?: AccordionMinimumHeight;
    alwaysOpen?: boolean;
    firstButton?(): void;
    secondButton?(): void;
    firstButtonDisabled?: boolean;
    secondButtonDisabled?: boolean;
    firstButtonLabel?: string;
    secondButtonLabel?: string;
    firstButtonType?: ButtonColorEnum;
    secondButtonType?: ButtonColorEnum;
    firstButtonOutline?:boolean;
    secondButtonOutline?:boolean;
    firstButtonShadow?:boolean;
    secondButtonShadow?:boolean;
    click():void; 
    backgroundColor?:AccordionBackgroundColor;
    hideFooter?:boolean;
    firstButtonId?: string;
    secondButtonId?: string;
}

export const Accordion = ({ open, disabled, label = "Accordion header", children, loading, loadingText, loadingTitle, minimumHeight = AccordionMinimumHeight.SMALL, alwaysOpen, firstButton, secondButton, firstButtonDisabled, secondButtonDisabled, firstButtonLabel = "tilbage", secondButtonLabel = "næste", firstButtonType = ButtonColorEnum.GREY, secondButtonType = ButtonColorEnum.DARK_GREEN, click, firstButtonOutline, secondButtonOutline, backgroundColor, firstButtonShadow, secondButtonShadow, hideFooter, firstButtonId, secondButtonId }: IAccordionProps) => {

    const [accordionState, setAccordionState] = useState<boolean>(open);
    
    useEffect(() => {
        if (alwaysOpen) {
            setAccordionState(true);
        }
    });

    const getMinimumHeightClass = (minimumHeight?: AccordionMinimumHeight) => {
        if (minimumHeight == AccordionMinimumHeight.EXTRASMALL) return 'nl-chevron-open-extrasmall';
        if (minimumHeight == AccordionMinimumHeight.SMALL) return 'nl-chevron-open-small';
        if (minimumHeight == AccordionMinimumHeight.MEDIUM) return 'nl-chevron-open-medium';
        else return 'nl-chevron-open-large';
    }

    const handleClick = ()=> {
        if (!alwaysOpen && !disabled && click !== undefined){
            click();
        }
    };

    return (
        <div className={`nl-chevron ${open ? `${getMinimumHeightClass(minimumHeight)}` : 'nl-chevron-closed'} ${disabled ? 'disabled-accordion' : '' } ${backgroundColor ? 'background-' + backgroundColor.toString() : ''}`}>

            {loading && <div className="overlay-container">
                <div className="overlay-title">{loadingTitle}</div>
                <div className="overlay-line" hidden={!loadingTitle}></div>
                <div className="overlay-message">{loadingText}</div>
                <div className="overlay-spinner">
                    <Loader size={LoaderSizeEnum.LARGE}></Loader>
                </div>
            </div>}

            <div className={`nl-chevron-header ${disabled ? 'disabled-accordion' : ''}`} onClick={handleClick} >
                <div className="nl-chevron-label">{label}</div>
                <div className={`nl-chevron-icons ${disabled ? 'disabled-icons' : ''} ${!open ? 'chevron-down' : 'chevron-up'}`} hidden={alwaysOpen}>
                    <Icon name={'Chevron Down'}></Icon>
                </div>
            </div>
            <div className={`nl-chevron-content ${!open ? 'accordion-content-hidden' : ''}`}>
                {children}
            </div>
            <div className={`nl-chevron-footer ${!!hideFooter ? 'chevron-footer-hidden' : ''}`}>
                {firstButton &&
                    <Button withShadow={!!firstButtonShadow} label={firstButtonLabel} color={firstButtonType} onClick={firstButton} disabled={firstButtonDisabled} outline={firstButtonOutline} id={firstButtonId} ></Button>
                }
                <span className="footer-span"></span>
                {secondButton &&
                    <Button withShadow={!!secondButtonShadow} label={secondButtonLabel} color={secondButtonType} onClick={secondButton} disabled={secondButtonDisabled} outline={secondButtonOutline} id={secondButtonId}></Button>
                }
            </div>
        </div>
    )
}