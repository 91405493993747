export enum ValidationTypeEnum{
    
    NOVALIDATION = "No validation",
    TEXT = "Text",
    NUMBER = "Number",
    PHONENUMBER = "Phonenumber",
    CPR = "CPR",
    CPR_AGE = "CPR_AGE",
    PASSWORD = "Password",
    EMAIL = "Email",
    NAME = "Name"
    
}