const getAgeInYears = (cprString: string) => {

    let cpr = Number(cprString);
    let century = Math.floor((cpr / 1000) % 10);
    let shortBirthYear = Math.floor(((cpr / 10000) % 100));
    let birthYear = 1900 + shortBirthYear;
    
    if ((century === 4 || century === 9) && shortBirthYear <= 36) birthYear += 100;
    else if (5 <= century && century <= 8 && shortBirthYear <= 57) birthYear += 100;

    let birthMonth = Math.floor((cpr / 1000000) % 100) -1;

    let birthDayOfMonth = Math.floor((cpr / 100000000) % 100);

    let birthDate = new Date(birthYear, birthMonth, birthDayOfMonth);
    let today = new Date();

    let birthDay = birthMonth === 2 && birthDayOfMonth === 29 && !isLeapYear(today.getFullYear()) ? new Date(today.getFullYear(), birthMonth, birthDayOfMonth -1) : new Date(today.getFullYear(), birthMonth, birthDayOfMonth);

    let birthdayAdjustment = today.getTime() < birthDay.getTime() ? -1 : 0;

    let age = today.getFullYear() - birthDate.getFullYear() + birthdayAdjustment;

    return age;

}

function isLeapYear(year):boolean {
    var d = new Date(year, 1, 28);
    d.setDate(d.getDate() + 1);
    return d.getMonth() == 1;
}


export {getAgeInYears};
