import * as React from 'react'
import { icons } from './Icons';
import "./Icon.scss";

export interface IconProps {
    name: string;
    small?:boolean;
}

export const Icon: React.FC<IconProps> = (props: IconProps) => {
    return (
        <div className="nl-icon noselect">        
            {<div dangerouslySetInnerHTML={{__html: icons[props.name + (props.small ? "-small" : "")]}}></div>}
        </div>        
    );
}
