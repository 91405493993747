import * as React from 'react';
import { LoaderColorEnum } from '../../models/loader/LoaderColorEnum';
import { LoaderSizeEnum } from '../../models/loader/LoaderSizeEnum';
import "./Loader.scss";



export interface LoaderProps {
    color?: LoaderColorEnum,
    size?: LoaderSizeEnum
}

export const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
    let size = LoaderSizeEnum.MEDIUM;
    let color = LoaderColorEnum.WHITE
    if (props.color) color = props.color
    if (props.size) size = props.size


    var classvar = `loader loader--${color} loader--${size}`

    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="15 15 65 65" preserveAspectRatio="xMidYMid"  className={classvar}>
<defs>
  <filter id="ldio-eyzavzigfmp-filter" x="-100%" y="-100%" width="300%" height="300%" color-interpolation-filters="sRGB">
    <feGaussianBlur in="SourceGraphic" stdDeviation="0.8"></feGaussianBlur>
    <feComponentTransfer result="cutoff">
      <feFuncA type="table" tableValues="0 0 0 0 0 0 1 1 1 1 1"></feFuncA>
    </feComponentTransfer>
  </filter>
</defs>
<g filter="url(#ldio-eyzavzigfmp-filter)"><g transform="translate(50 50)">
<g transform="rotate(230.19)">
  <circle cx="18" cy="0" r="2.28187" fill="#ffdee5">
    <animate attributeName="r" keyTimes="0;0.5;1" values="1.2;2.8;1.2" dur="6.666666666666666s" repeatCount="indefinite" begin="-0.15000000000000005s"></animate>
  </circle>
  <animateTransform attributeName="transform" type="rotate" keyTimes="0;1" values="0;360" dur="6.666666666666666s" repeatCount="indefinite" begin="0s"></animateTransform>
</g>
</g><g transform="translate(50 50)">
<g transform="rotate(102.693)">
  <circle cx="18" cy="0" r="2.21569" fill="#ffdee5">
    <animate attributeName="r" keyTimes="0;0.5;1" values="1.2;2.8;1.2" dur="3.333333333333333s" repeatCount="indefinite" begin="-0.1285714285714286s"></animate>
  </circle>
  <animateTransform attributeName="transform" type="rotate" keyTimes="0;1" values="0;360" dur="3.333333333333333s" repeatCount="indefinite" begin="-0.021428571428571432s"></animateTransform>
</g>
</g><g transform="translate(50 50)">
<g transform="rotate(337.511)">
  <circle cx="18" cy="0" r="1.30733" fill="#ffdee5">
    <animate attributeName="r" keyTimes="0;0.5;1" values="1.2;2.8;1.2" dur="2.222222222222222s" repeatCount="indefinite" begin="-0.10714285714285716s"></animate>
  </circle>
  <animateTransform attributeName="transform" type="rotate" keyTimes="0;1" values="0;360" dur="2.222222222222222s" repeatCount="indefinite" begin="-0.042857142857142864s"></animateTransform>
</g>
</g><g transform="translate(50 50)">
<g transform="rotate(214.649)">
  <circle cx="18" cy="0" r="2.45087" fill="#ffdee5">
    <animate attributeName="r" keyTimes="0;0.5;1" values="1.2;2.8;1.2" dur="1.6666666666666665s" repeatCount="indefinite" begin="-0.08571428571428573s"></animate>
  </circle>
  <animateTransform attributeName="transform" type="rotate" keyTimes="0;1" values="0;360" dur="1.6666666666666665s" repeatCount="indefinite" begin="-0.0642857142857143s"></animateTransform>
</g>
</g><g transform="translate(50 50)">
<g transform="rotate(94.0937)">
  <circle cx="18" cy="0" r="1.98496" fill="#f8232d">
    <animate attributeName="r" keyTimes="0;0.5;1" values="1.2;2.8;1.2" dur="1.333333333333333s" repeatCount="indefinite" begin="-0.0642857142857143s"></animate>
  </circle>
  <animateTransform attributeName="transform" type="rotate" keyTimes="0;1" values="0;360" dur="1.333333333333333s" repeatCount="indefinite" begin="-0.08571428571428573s"></animateTransform>
</g>
</g><g transform="translate(50 50)">
<g transform="rotate(335.851)">
  <circle cx="18" cy="0" r="1.5998" fill="#f8232d">
    <animate attributeName="r" keyTimes="0;0.5;1" values="1.2;2.8;1.2" dur="1.111111111111111s" repeatCount="indefinite" begin="-0.042857142857142864s"></animate>
  </circle>
  <animateTransform attributeName="transform" type="rotate" keyTimes="0;1" values="0;360" dur="1.111111111111111s" repeatCount="indefinite" begin="-0.10714285714285716s"></animateTransform>
</g>
</g><g transform="translate(50 50)">
<g transform="rotate(219.953)">
  <circle cx="18" cy="0" r="2.79514" fill="#f8232d">
    <animate attributeName="r" keyTimes="0;0.5;1" values="1.2;2.8;1.2" dur="0.9523809523809521s" repeatCount="indefinite" begin="-0.021428571428571432s"></animate>
  </circle>
  <animateTransform attributeName="transform" type="rotate" keyTimes="0;1" values="0;360" dur="0.9523809523809521s" repeatCount="indefinite" begin="-0.1285714285714286s"></animateTransform>
</g>
</g></g>
</svg>
    )

}