import axios from 'axios';

const dawaUrl = "https://dawa.aws.dk/adresser/autocomplete?";

const addressAutocomplete = (query:string, fuzzy:boolean)=> {

    return axios.get(`${dawaUrl}${fuzzy ? 'fuzzy=&' : ''}q=${query}`);

};

export default addressAutocomplete;