import React, { useEffect, useRef, useState } from "react";
import { ValidationTypeEnum } from "../../models/input-component/ValidationTypeEnum";
import "./Input-Component.scss";
import { getAgeInYears } from "../../services/cprservice";
import { InputTypeEnum } from "../../models/input-component/InputTypeEnum";

export interface IInputComponentProps {
	change(text: IInputValidationResult): React.ChangeEvent<HTMLInputElement>;
	focus?: () => void;
	touchStart?: () => void;
	blur?: () => void;
	customValidator?(text: string): boolean;
	placeholder?: string;
	width?: number;
	value?: string;
	validationType: ValidationTypeEnum;
	customTextValidationError?: string;
	customNumberValidationError?: string;
	customPhoneNumberValidationError?: string;
	customCprValidationError?: string;
	customPasswordValidationError?: string;
	customEmailValidationError?: string;
	customNameValidationError?: string;
	customValidatorError?: string;
	tabIndex?: number;
	inputType?: InputTypeEnum;
	disabled?: boolean;
	minLength?: number;
	maxLength?: number;
}

export interface IInputValidationResult {
	value: string;
	valid: boolean;
	error: string;
}

export const InputComponent = ({
	value = "",
	placeholder,
	change,
	width,
	validationType = ValidationTypeEnum.NOVALIDATION,
	customCprValidationError,
	customNumberValidationError,
	customPhoneNumberValidationError,
	customTextValidationError,
	customPasswordValidationError,
	customEmailValidationError,
	customNameValidationError,
	customValidatorError,
	tabIndex,
	customValidator,
	inputType = InputTypeEnum.TEXT,
	focus,
	touchStart,
	blur,
	disabled,
	minLength,
	maxLength,
}: IInputComponentProps) => {
	const [text, setText] = useState(null);
	const [error, setError] = useState("");
	const [hasFocus, setHasFocus] = useState(false);
	const inputRef = useRef<HTMLInputElement>();

	let hasErrors: boolean = false;
	let errorMessage: string = "";

	useEffect(() => {
		setText(value);
		inputRef.current.value = value;
	}, [value]);

	const handleTextChange = () => {
		setText(inputRef.current.value);
	};

	const handleBlur = () => {
		validateInput();
		setHasFocus(false);
	};

	const handleOnFocus = () => {
		setHasFocus(true);
	};

	const handleOnKeyUp = () => {
		validateInput(true);
	};

	const validateInput = (hideMessages: boolean = false) => {
		setError("");
		hasErrors = false;
		errorMessage = "";

		if (customValidator != null) {
			if (!customValidator(text)) {
				errorMessage = customValidatorError ?? "Ugyldig indtastning";

				change({
					value: text,
					valid: error.length === 0,
					error: errorMessage,
				});

				return;
			}
		}

		if (validationType == ValidationTypeEnum.TEXT) {
			if (!text || text.length === 0) {
				hasErrors = true;
				errorMessage = customTextValidationError ?? "Feltet må ikke være tomt";
			}
		}

		if (validationType == ValidationTypeEnum.NUMBER) {
			if (!text || isNaN(text)) {
				hasErrors = true;
				errorMessage = customNumberValidationError ?? "Feltet er ikke et gyldigt nummer";
			}
		}

		if (validationType == ValidationTypeEnum.PHONENUMBER) {
			if (!/^[0-9]{8}$/.test(text)) {
				hasErrors = true;
				errorMessage =
					customPhoneNumberValidationError ?? "Feltet er ikke et gyldigt telefonnummer";
			}
		}

		if (validationType == ValidationTypeEnum.CPR) {
			if (!/^[0-9]{10}$|^[0-9]{6}[-][0-9]{4}$|^[0-9]{6}\s[0-9]{4}$/.test(text)) {
				hasErrors = true;
				errorMessage = customCprValidationError ?? "Ugyldigt CPR-nummer indtastet";
			}
		}

		if (validationType == ValidationTypeEnum.CPR_AGE) {
			if (!/^[0-9]{10}$|^[0-9]{6}[-][0-9]{4}$|^[0-9]{6}\s[0-9]{4}$/.test(text)) {
				hasErrors = true;
				errorMessage = customCprValidationError ?? "Ugyldigt CPR-nummer indtastet";
			} else {
				if (getAgeInYears(text) < 18) {
					hasErrors = true;
					errorMessage = customCprValidationError ?? "Du skal være over 18 år";
				}
			}
		}

		if (validationType == ValidationTypeEnum.PASSWORD) {
			if (
				!/^(?=.*[A-Za-zæøåÆØÅ])(?=.*\d)[A-Za-zÆØÅæøå\d!$%@#£€*?&,\/\\.\-_:;=()"']{6,}$/.test(text)
			) {
				hasErrors = true;
				errorMessage = customPasswordValidationError ?? "Ugyldigt password indtastet";
			}
		}

		if (validationType == ValidationTypeEnum.EMAIL) {
			if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(text)) {
				hasErrors = true;
				errorMessage = customEmailValidationError ?? "Ugyldig e-mail indtastet";
			}
		}

		if (validationType == ValidationTypeEnum.NAME) {
			if (!/([a-zA-ZÆØÅæøå]|[à-úÀ-Ú]+\s?\b){2,}/.test(text)) {
				hasErrors = true;
				errorMessage = customNameValidationError ?? "Indtast dit fulde navn";
			}
		}

		if (!hideMessages) {
			setError(errorMessage);
		}

		change({
			value: validationType === ValidationTypeEnum.CPR ? replaceCprCharacters(text) : text,
			valid: !hasErrors,
			error: errorMessage,
		});
	};

	const replaceCprCharacters = (value: string): string => {
		let cpr = value;
		cpr = cpr.replace(" ", "").replace("-", "");
		return cpr;
	};

	const getInputType = (): string => {
		let type: string = "text";

		if (inputType === InputTypeEnum.TEXT) type = "text";
		else if (inputType === InputTypeEnum.NUMBER) type = "number";
		else if (inputType === InputTypeEnum.PHONENUMBER) type = "tel";
		else if (inputType === InputTypeEnum.EMAIL) type = "email";
		else if (inputType === InputTypeEnum.PASSWORD) type = "password";
		else if (validationType == ValidationTypeEnum.PASSWORD) type = "password";

		return type;
	};

	return (
		<div>
			<div className="input-element-parent">
				<input
					minLength={minLength}
					maxLength={maxLength}
					disabled={disabled}
					onBlurCapture={blur}
					onTouchStart={touchStart}
					onFocusCapture={focus}
					type={getInputType()}
					style={{ width: width }}
					className={`input-element ${error.length > 0 ? "error-border" : ""}`}
					onChange={handleTextChange}
					onFocus={handleOnFocus}
					onBlur={handleBlur}
					onKeyUp={handleOnKeyUp}
					ref={inputRef}
					tabIndex={tabIndex ?? 0}
					required></input>
				<div
					className={`input-placeholder ${
						inputRef?.current?.value?.length > 0 ? "placeholder-top" : ""
					}`}>
					{placeholder}
				</div>
			</div>
			{error.length > 0 && <div className="error-section">{error}</div>}
		</div>
	);
};
