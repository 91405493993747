import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import '../checkbox/Checkbox.scss'

export interface ICheckboxProps {
    required?: boolean;
    disabled?: boolean;
    checked?: boolean;
    label?: string;
    change(checked: boolean): ChangeEvent<HTMLInputElement>;
}

export const Checkbox = ({ required, disabled, checked = false, label, change }: ICheckboxProps) => {

    const [isChecked, setIsChecked] = useState(checked);

    const checkedRef = useRef<HTMLInputElement>();

    const onHandleChange = () => {
        setIsChecked(val => !val);
        change(checkedRef.current.checked);
    }

    return (
        <label className="nl-checkbox-layout">
            <div className="nl-checkbox-layout-inner">
                <input
                    className="nl-checkbox-input"
                    type="checkbox"
                    checked={isChecked}
                    required={required}
                    disabled={disabled}
                    onChange={onHandleChange}
                    ref={checkedRef}
                />

                <div className={`nl-checkbox-frame disabled' : 'nl-checkbox-frame`}>
                    <svg
                        version="1.1"
                        focusable="false"
                        className="mat-checkbox-checkmark"
                        viewBox="0 0 24 24"
                        xmlSpace="preserve"
                    >
                        <path
                            className="mat-checkbox-checkmark-path"
                            fill="none"
                            stroke="white"
                            d="M4.1,12.7 9,17.6 20.3,6.3"
                        />
                    </svg>
                </div>
                <div className="nl-checkbox-label">
                    <span dangerouslySetInnerHTML={{ __html: label }}></span>
                </div>
            </div>
        </label>
    );
}



