import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
// import addressAutocomplete from '../../services/dawaservice'
import './Dropdown.scss';

export interface IDropdownProps {
    change(text: string): React.ChangeEvent<HTMLInputElement>;
    placeholder: string;
    values: string[];
    width?: number;
    newSelectedValue?: string;
}

export enum ScrollDirection {
    UP, DOWN
}

export const Dropdown = ({ change, placeholder = 'Vælg element', values = ["Djøf", "CoolShop"], width, newSelectedValue ="" }: IDropdownProps) => {

    let [selectedIndex, setSelectedIndex] = useState(undefined);
    let [valuesClosed, setValuesClosed] = useState<Boolean>(true);
    let [newValueBuf, setNewValueBuf] = useState("")



    const dropdownRef = useRef<any>();
    const dropdownParentRef = useRef<any>();
    const listRef = useRef<any>();

    useEffect(() => {
        if (width) {
            dropdownParentRef.current.style.width = `${width}px`;
        }
        if ((newSelectedValue !== "" && newValueBuf !== "")||(newSelectedValue !== "" && newSelectedValue!== newValueBuf)) {
            setNewValueBuf(newSelectedValue)
            selectValue(newSelectedValue)
            if (values.includes(newSelectedValue)) {
                setSelectedIndex(values.indexOf(newSelectedValue))
            }
        }
    }, [width]);

    const handleClick = () => {

        if (values.length === 0) {
            setValuesClosed(true);
            return;
        }
        setValuesClosed(!valuesClosed);
    };

    const createResult = (value: string) => {
        change(value);
    }

    const selectValue = (value: string): void => {
        createResult(value);
        dropdownRef.current.value = value;
        setValuesClosed(true);
    };

    return (
        <div>
            <div className="input-element-parent" ref={dropdownParentRef}>
                <input className="input-element" readOnly={true} onClick={handleClick} ref={dropdownRef} required></input>
                {!valuesClosed &&
                <div ref={listRef} className={`values`} tabIndex={-1}>
                    {values.map((item: string, index: number) =>
                        <div key={item} data-value={item} id={item} className={index == selectedIndex ? 'selected-value value-item' : 'value-item'} onClick={(item) => {
                            let target = (values[index] as string);
                            if (target) {
                                selectValue(target);
                                setSelectedIndex(index);
                            }
                        }}>{item}</div>
                    )}
                </div>
                }
                <div className={selectedIndex !== undefined ? "input-placeholder placeholder-top" : "input-placeholder"}>{placeholder}</div>
            </div>
        </div>
    )
}
