import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '../icon/Icon';
import './Modal.scss';
import {useOnClickOutside} from '../../hooks/ClickOutside';

export interface IModalProps {
    children: React.ReactNode;
    visible: boolean;
    onClosing():void;
}

export const Modal = ({ children, visible, onClosing }: IModalProps) => {

    const modalRef = useRef<HTMLDivElement>();
    const [showModal, setShowModal] = useState(visible)

    useOnClickOutside(modalRef, () => {
       handleCloseClick();
    });

    useEffect(() => {
        toggleModalHeight(visible);
        setShowModal(visible);
    }, [visible]);

    const handleCloseClick = () => {
        setShowModal(false);
        toggleModalHeight(false);
        
        if (showModal){
            onClosing();
        }
       
    };

    const toggleModalHeight = (show: boolean) => {
        if (show) {
            
            modalRef.current.style.top = "";
            modalRef.current.style.top = window.innerWidth <= 768 ? 'calc(0%)' : `calc(50% - ${modalRef.current?.clientHeight / 2}px)`;
        }
        else {
            modalRef.current.style.top = "";
            modalRef.current.style.top = "-10000px !important;";
        }
    }

    return (
        <div>
            <div className={`nl-modal-overlay ${showModal ? 'nl-overlay-visible' : ''}`}></div>
            <div className={`nl-modal`} ref={modalRef}>
                <div className="nl-modal-close" onClick={handleCloseClick}>
                    <Icon name="Close"></Icon>
                </div>
                {children}
            </div>
        </div>
    );
};
