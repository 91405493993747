import React, { useEffect, useRef, useState } from 'react';
import "./Email.scss";

export interface IEmailProps {
    change(text: any): React.ChangeEvent<HTMLInputElement>;
    focus?: ()=> void;
    touchStart?:()=> void;
    blur?:()=> void;
    width?: Number;
    hideErrors?: Boolean;
    disableCopyPaste?: Boolean;
}

export const Email = ({ change, width, hideErrors, disableCopyPaste, focus, touchStart, blur }: IEmailProps) => {

    enum StandardErrorMessages {
        INVALIDMAILS = 'Ugyldig e-mail(s) indtastet',
        NOTMATCHING = 'Mails matcher ikke'
    }

    const emailRegex = /^[a-zA-ZÆØÅæøå0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-ZÆØÅæøå0-9-]+(?:\.[a-zA-ZÆØÅæøå0-9-]+)*$/;

    const emailRef = useRef<any>();
    const emailRepeatRef = useRef<any>();
    const errorRef = useRef<any>();

    let [hasFocus, setHasFocus] = useState(false);
    let [hasFocusRepeat, setHasFocusRepeat] = useState(false);
    let [errors, setErrors] = useState(Array<String>());

    const handleTextChange = async (e: any) => {

        if (change) {

            let errors = await validEmail();

            setErrors(errors);

            setHasFocus(false);

            change({
                "value": emailRef.current.value,
                "errors": errors,
                "touched": true,
                "valid": errors.length === 0
            });
        }
    }

    useEffect(() => {

        if (width) {
            emailRef.current.style.width = `${width}px`;
            emailRepeatRef.current.style.width = `${width}px`;
        }

        if (hideErrors) {
            errorRef.current.style.display = "none";
        }

        if (disableCopyPaste) {
            emailRef.current.onpaste = e => e.preventDefault();
            emailRepeatRef.current.onpaste = e => e.preventDefault();
        }
    });

    const handleTextChangeRepeat = async (e: any) => {
        if (change) {

            let errors = await validEmail()

            setErrors(errors);

            setHasFocusRepeat(false);

            change({
                "value": emailRepeatRef.current.value,
                "errors": errors,
                "touched": true,
                "valid": errors.length === 0
            });
        }
    }

    const validEmail = () => {

        errors = [];

        let email = emailRef.current.value;
        let emailRepeat = emailRepeatRef.current.value;

        let valid = emailRegex.test(email);
        let validRepeat = emailRegex.test(emailRepeat);

        if (!valid || !validRepeat) {
            errors.push(StandardErrorMessages.INVALIDMAILS);
        }

        if (email !== emailRepeat) {
            errors.push(StandardErrorMessages.NOTMATCHING);
        }

        return errors;
    }

    const handleFocus = () => {
        setHasFocus(true);
    };

    const handleFocusRepeat = () => {
        setHasFocusRepeat(true);
    };

    const showError = (errors: String[]) => {

        if (errors?.length === 0) return <></>;
        return errors.map(item => <div>{item}</div>);
    }

    return (

        <div className="email-parent">
            <div className="input-element-parent">

                <input onTouchStart={touchStart} onBlurCapture={blur} onFocusCapture={focus} className={(!hasFocus && !hasFocusRepeat && errors.length > 0) ? 'input-element error-border' : 'input-element'} onBlur={handleTextChange} onFocus={handleFocus} ref={emailRef} required></input>
                <div className="input-placeholder">Indtast E-mail</div>
            </div>
            <div className="input-element-parent" style={{ marginTop: 15 }}>
                <input onTouchStart={touchStart} onBlurCapture={blur} onFocusCapture={focus} className={(!hasFocus && !hasFocusRepeat && errors.length > 0) ? 'input-element error-border' : 'input-element'} onBlur={handleTextChangeRepeat} onFocus={handleFocusRepeat} ref={emailRepeatRef} required ></input>
                <div className="input-placeholder">Gentag E-mail</div>
            </div>
            <div className="error-section" ref={errorRef}>
                {showError(errors)}
            </div>
        </div>
    );

}