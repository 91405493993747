import React, { useEffect } from "react";
import { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { Icon } from "../icon/Icon";
import "./DatePicker.scss";
import "../../styles/react-datepicker.min.css";
import da from "date-fns/locale/da";

registerLocale("da", da);

export interface IDatePickerProps {
  change(text: Date): React.ChangeEvent<HTMLInputElement>
  placeholder: string;
  width?: number;
  minDate?: string;
  maxDate?: string;
  keepOpenOnSelect?: boolean;
  openInModal?: boolean;
  showTimepicker?: boolean;
  timepickerInterval?: number;
  value?: Date;
  filterDate?: (date: Date) => void
  filterTime?: (date: Date) => void
}

export const DatePickerComponent = ({ change, placeholder = 'Vælg dato', width = 190, minDate, maxDate, keepOpenOnSelect, openInModal, showTimepicker, timepickerInterval, value, filterDate, filterTime }: IDatePickerProps) => {

  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    if (value) {
      setStartDate(value)
    }
  }, [value])

  const handleDateChange = (date: Date) => {
    setStartDate(date);
    change(date);
  };

  const parseDate = (date?: Date):string => {
  
    if (!date) return ""
    
    if (showTimepicker){
      return `${date.toLocaleDateString("da-DK")} ${date.toLocaleTimeString().substr(0, date.toLocaleTimeString().length -3)}`
    }
    else{
      return `${date.toLocaleDateString("da-DK")}`
    }
  }

  const calendarField =
    <div className="calender-container" style={{ width }}>
      <input className="calender-input" value={parseDate(startDate)} readOnly required></input>
      <div className={startDate != null ? "calendar-placeholder placeholder-top-calendar" : "calendar-placeholder"}>{placeholder}</div>
      <span className="calendar-icon">
        <Icon name="Calendar" small={true}></Icon>
      </span>
    </div>

  return (
    <DatePicker
      selected={startDate}
      onChange={date => handleDateChange(date)}
      locale={da}
      minDate={minDate ? new Date(minDate) : null}
      maxDate={maxDate ? new Date(maxDate) : null}
      shouldCloseOnSelect={!keepOpenOnSelect}
      todayButton="Idag"
      withPortal={openInModal}
      customInput={calendarField}
      showTimeSelect={!!showTimepicker}
      filterDate={filterDate || null}
      filterTime={filterTime || null}
      timeIntervals={timepickerInterval || 15}

    />
  )

};
