import * as React from 'react';
import { useState } from 'react';
import "./Input-Field.scss";
import { InputfieldStatusEnum } from '../../models/input-field/InputfieldStatusEnum'
import { InputfieldTypeEnum } from '../../models/input-field/InputfieldTypeEnum'
import { InputfieldValidatorEnum } from '../../models/input-field/InputfieldValidatorEnum'
import { Icon } from '../icon/Icon';

enum StandardErrorMessages {
    EMAIL = 'Den indtastet e-mail er ugyldig',
    PASSWORD = 'Kodeord skal indeholde ...',
    PHONENUMBER = 'Indtast et gyldigt telefonnummer',
    CUSTOM = 'Noget gik galt'
}

export interface InputFieldProps {
    id: string;
    type: InputfieldTypeEnum;
    onInputText: (event: React.ChangeEvent<HTMLInputElement>) => void;
    
    validateOn: InputfieldValidatorEnum;
    autoComplete?: string;
    placeholder?: string;
    status?: InputfieldStatusEnum;
    value?: string;
    errorMessage?: string;
    icon?: string;
    iconPosition?: string;
    iconHoverText?: string;
    onIconClick?: () => void;
    onKeyUp?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
    customValidator?: (value: string) => boolean;
    handleChange?: (errorMessage: string, status: InputfieldStatusEnum) => void;
}

export interface ErrorProps {
    errorMessage: string;
    status: string;
}

const InputFieldInput: React.FC<InputFieldProps> = (props: InputFieldProps) => {
    let classvar = "status--basic";
    if (props.value) {
        classvar += " status--dirty"
    }

    if (props.icon) {
        classvar += " icon-position--" + props.iconPosition;
    }

    const onBluring = () => {
        if (props.value && props.handleChange) {
            const {passedValidation, meesage} = validator(props.value, props.validateOn, props.customValidator);

            if (!passedValidation) {
                props.handleChange(props.errorMessage ? props.errorMessage : meesage, InputfieldStatusEnum.ERROR);
            }
            else {
                props.handleChange('', InputfieldStatusEnum.SUCCESS);
            }
        }
    }

    return (
        <input
            className={classvar}
            id={props.id}
            autoComplete={props.autoComplete}
            value={props.value}
            type={props.type}
            onKeyUp={props.onKeyUp}     
            onFocus={props.onFocus}
            onInput={props.onInputText}
            onBlur={onBluring}       
            >
        </input>
    );
}

const PlaceHolder: React.FC<InputFieldProps> = (props: InputFieldProps) => {
    if (props.placeholder) {
        return (<div className={`placeholder ${props.iconPosition}`}>
            {props.placeholder}
        </div>)
    }

    return (<></>);
}

const InputFieldIcon: React.FC<InputFieldProps> = (props: InputFieldProps) => {
    if (props.icon) {
        return(
            <div className={`icon ${props.iconPosition} noselect`} onClick={props.onIconClick}>
                <Icon name={props.icon}></Icon>
                <div className='icon-hover-text'>{props.iconHoverText}</div>
            </div>
        )
     }
     return (<></>);
}

const ErrorMessage: React.FC<ErrorProps> = (props: ErrorProps) => {
    if (props.errorMessage && props.status === InputfieldStatusEnum.ERROR) {
        return (
            <div className='error-message'>{props.errorMessage}</div>
        )
    }
    return (<></>);
}

export const InputField: React.FC<InputFieldProps> = (props: InputFieldProps) => {
    const [errorMessage, setErrorMessage] = useState(props.errorMessage ? props.errorMessage : '');
    const [status, setStatus] = useState(props.status ? props.status : InputfieldStatusEnum.BASIC);

    if (props.errorMessage && props.errorMessage != errorMessage) setErrorMessage(props.errorMessage);
    const handleChange = (errorMessage: string, status: InputfieldStatusEnum) => {
        setErrorMessage(errorMessage);
        setStatus(status)
    }

    let inputFieldProps: InputFieldProps = {...props, handleChange}
    let errorProps: ErrorProps = {errorMessage, status}

    return (
        <div className='field'>
            <div className={`input-wrapper ${props.iconPosition}`}>
                <InputFieldInput {...inputFieldProps}/>
                <PlaceHolder {...props}/>
                <InputFieldIcon {...props}/>
            </div>
            <ErrorMessage {...errorProps}/>
        </div>  
    );
}

const validator = (value: string, validateOn: InputfieldValidatorEnum, customValidator?: (value: string) => boolean) => {
    if (customValidator) {
        return {passedValidation: customValidator(value), meesage: StandardErrorMessages.CUSTOM}
    }

    switch (validateOn) {
        case InputfieldValidatorEnum.EMAIL:
            return {passedValidation: emailValidation(value), meesage: StandardErrorMessages.EMAIL}
        case InputfieldValidatorEnum.PASSWORD:
            return {passValidation: passwordValidation(value), meesage: StandardErrorMessages.PASSWORD}
        case InputfieldValidatorEnum.PHONENUMBER:
            return {passedValidation: phoneNumberValidation(value), meesage: StandardErrorMessages.PHONENUMBER}
        case InputfieldValidatorEnum.NOVALIDATION:
            return {passwordValidation: true, message: ""}
        default:
            return {passedValidation: false, meesage: StandardErrorMessages.CUSTOM}
    }
}

const emailValidation = (value: string) => {
    return (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value));
}

const phoneNumberValidation = (value: string) => {
    return value.length === 8 || value.length === 11;
}

const passwordValidation = (value: string) => {
    return (/^(?=.*[A-Za-zæøåÆØÅ])(?=.*\d)[A-Za-zÆØÅæøå\d!$%@#£€*?&,\/\\.\-_:;=()"']{6,}$/.test(value));
}
